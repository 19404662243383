.App {
  font-family: sans-serif;
  text-align: center;
}

.scene-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scene-container canvas {
  display: block;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.canvas {
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0icmdiYSgyMTEsMjExLDIxMSwwLjUpIi8+PC9zdmc+Cg=="),
    auto;
}
.predefined-images {
  position: fixed;
  top: 50%;
  left: 1;
  transform: translateY(-50%);
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  height: 400px; /* adjust this value as needed */
  /* adjust this as per your requirement */
  overflow-y: auto; /* enable vertical scroll */
}

.predefined-images img {
  width: auto;
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  width: 70px;
  height: 70px;
}

.predefined-images img:hover {
  transform: scale(1.05);
}

.predefined-images-header {
  width: 100%;
  text-align: center;
  background-color: #f8f9fa; /* You can change the color as needed */
  padding: 5px 0;
  border-radius: 10px 10px 0 0; /* Rounded corners at the top */
}

.predefined-images-header h2 {
  margin: 0;
  font-size: 0.8em;
  color: #333;
}
/* smaller images and container on mobile */
@media (max-width: 600px) {
  .predefined-images {
    height: 230px; /* adjust this as per your requirement */
    padding: 5px; /* adjust padding as per your requirement */
  }
  .predefined-images img {
    width: 60px;
    height: 60px; /* adjust this value as needed */
  }
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
